.privacyPolicy {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    border-radius: 8px;
}

.privacyPolicy h1,
.privacyPolicy h2,
.privacyPolicy h3,
.privacyPolicy h4 {
    color: #2c3e50;
    margin-bottom: 20px;
}

.privacyPolicy h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    text-align: center;
}

.privacyPolicy h2 {
    font-size: 2em;
}

.privacyPolicy h3 {
    font-size: 1.75em;
}

.privacyPolicy h4 {
    font-size: 1.5em;
}

.privacyPolicy p,
.privacyPolicy ul,
.privacyPolicy li {
    margin-bottom: 15px;
    font-size: 1em;
}

.privacyPolicy ul {
    padding-left: 20px;
}

.privacyPolicy ul li {
    list-style-type: disc;
}

.privacyPolicy a {
    color: #3498db;
    text-decoration: none;
}

.privacyPolicy a:hover {
    text-decoration: underline;
}

.privacyPolicy strong {
    font-weight: bold;
}
