.App {
  font-family: 'Urbanist', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}


.nav {
  text-align: left;
  font-size: 30px;
  height: 5vh;
  cursor: pointer;
}

.nav p {
  margin: 10px 0 0 5vw;
  color: #BB254A;
  font-weight: bold;
}

.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5vw;
}

.picture {
  align-items: start;
}

.picture img {
  height: 70vh;
}

.content {
  width: 100vw;
}

.logo-mission {
  height:30vh
}

.content img{
  width: 100px;
}

.together_we_are_complete {
  color: #CCCCCC;
  /* height: 50vh; */
}

.mission{
  text-align: left;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: self-start;
  height: 30vh;
}

.softwares {
  color: #1ABCFE;
}

.privacy{
  color: #BD863C;
}

.freedom {
  color: #38BDF8;
}

.line{
  margin: 0;
  font-size: large;
  text-align: left;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 20px;
}

footer {
  height: 5vh;
}

.privacy-policy {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
}

.tm {
  font-size: 70%;
    vertical-align: super;
}

.privacy-policy span {
  margin-left: 20px;
}

.zetarya {
  width: 30%;
  margin: 10px 0 0 5vw;
}

.zetarya img {
  width: 30%;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .picture{
    display: none;
  }
  .mission{
    font-size: 8px;
    height: 50vh;
    justify-content: center;
  }

  .line {
    font-size: 10px;
  }

  .footer {
    font-size: 10px;
  }

  .zetarya img {
    width: 50%;
  }
  
}